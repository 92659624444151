import { renderBlocs } from "./blocs";
import { string_to_slug, capitalizeFirstName } from "./utils";
import { usePost, useFetch } from "./fetch";

// this is a comment

function popAlert(message, ok) {
  const $alert = document.querySelector(".alert");
  if (ok) {
    $alert.classList.remove("error");
  } else {
    $alert.classList.add("error");
  }
  $alert.innerHTML = `<p>${message}</p>`;
  $alert.style.display = "flex";
  setTimeout(() => {
    $alert.style.display = "none";
  }, 10000);
}

function handleAdhesion() {
  const $adhesionIndividuelle = document.getElementById(
    "adhesionIndividuelle"
  );
  const $adhesionStructure = document.getElementById(
    "adhesionStructure"
  );
  const $individualForm = document.querySelector(".individual");
  const $structureForm = document.querySelector(".structure");

  const $domaine = document.getElementById("domaine");
  const $education = document.getElementById("education");
  const $culture = document.getElementById("culture");
  const $enseignant = document.getElementById("enseignantBool");
  // const $autreProfession = document.getElementById("autreProfession");
  const $enseignantForm = document.querySelector(".enseignant");
  const $nonEnseignantForm =
    document.querySelector(".non-enseignant");

  const $grade = document.getElementById("grade");
  const $firstGrade = document.querySelector(".firstGrade");
  const $secondGrade = document.querySelector(".secondGrade");
  const $superior = document.querySelector(".superior");

  const $typeA = document.getElementById("typeA");
  const $typeB = document.getElementById("typeB");
  const $typeC = document.getElementById("typeC");

  const $tarifA = document.getElementById("tarifA");
  const $tarifB = document.getElementById("tarifB");
  const $tarifC = document.getElementById("tarifC");

  const $adhesionForm = document.getElementById("adhesionForm");

  const adhesionTypeError = document.getElementById(
    "adhesionTypeError"
  );

  async function sendForm(values) {
    const $output = document.getElementById("output");
    $output.innerHTML = "";

    if (!values.culturalProfession) {
      delete values.culturalProfession;
    }
    if (!values.domaine) {
      delete values.domaine;
    }
    if (!values.function) {
      delete values.function;
    }
    if (values.academy1 || values.academy2) {
      values.academy = values.academy1 || values.academy2;
    }

    if (values.schoolType1 || values.schoolType2) {
      values.schoolType = values.schoolType1 || values.schoolType2;
    }

    if (values.subjects || values.subject2) {
      values.subject = values.subjects || values.subject2;
    }

    if (
      values.adhesionStructureType === "A" &&
      values.adhesionTarifA
    ) {
      values.adhesionTarif = values.adhesionTarifA;
    } else if (
      values.adhesionStructureType === "B" &&
      values.adhesionTarifB
    ) {
      values.adhesionTarif = values.adhesionTarifB;
    } else if (
      values.adhesionStructureType === "C" &&
      values.adhesionTarifC
    ) {
      values.adhesionTarif = values.adhesionTarifC;
    }

    /* POST request */
    let response = await usePost("adherents-2025-s", values);

    if (response.status === 200) {
      document.getElementById("submit").style =
        "pointer-events:none;";
      popAlert(
        "Votre adhésion a bien été enregistré, vous allez être redirigé vers la page de paiement.",
        true
      );
      if (values.adhesionType === "individuelle") {
        // POST Carte Adhérents
        if (values.authCarteIndividual) {
          let { firstName, lastName, profession, zipcode } = values;
          let mapValues = {
            firstName,
            lastName,
            profession,
            zipcode,
            adherent: true,
            type: "individuel",
          };
          let mapResponse = await usePost(
            "carte-adherents-2-s",
            mapValues
          );
          console.log(`mapResponse`, mapResponse);
        }
        // POST /subscribe-indi
        let mailResponse = await usePost("subscribe-indi", {
          email: values.email,
        });
        console.log(`mailResponse`, mailResponse);
        console.log("values.adhesionTarif", values.adhesionTarif);

        // redirect to Stripe
        if (values.reglement === "cb") {
          if (values.adhesionTarif === "tarifI1") {
            setTimeout(() => {
              window.location.href =
                "https://buy.stripe.com/6oE3er6eh3LgdgY9AF";
            }, 4000);
          } else if (values.adhesionTarif === "tarifI2") {
            setTimeout(() => {
              window.location.href =
                "https://buy.stripe.com/3csg1d7il5ToccU4gm";
            }, 4000);
          } else if (values.adhesionTarif === "tarifI3") {
            setTimeout(() => {
              window.location.href =
                "https://buy.stripe.com/5kAcP17il5Toel2aEL";
            }, 4000);
          }
          /* setTimeout(() => {
              window.location.href =
                "https://buy.stripe.com/00gaGTaux0z47WE5kk";
            }, 4000); */
        } else if (values.reglement === "cheque") {
          if (values.adhesionTarif === "tarifI1") {
            window.location.href = "/cheque/individuel1";
          } else if (values.adhesionTarif === "tarifI2") {
            window.location.href = "/cheque/individue2";
          } else if (values.adhesionTarif === "tarifI3") {
            window.location.href = "/cheque/individuel3";
          }
        } else if (values.reglement === "rib") {
          if (values.adhesionTarif === "tarifI1") {
            window.location.href = "/rib/individuel1";
          } else if (values.adhesionTarif === "tarifI2") {
            window.location.href = "/rib/individuel2";
          } else if (values.adhesionTarif === "tarifI3") {
            window.location.href = "/rib/individuel3";
          }
        }
      } else if (values.adhesionType === "structure") {
        // POST Carte Adhérents
        if (values.authCarteStructure) {
          let { structureName, structureWebsite } = values;
          let mapValues = {
            structureName,
            structureWebsite,
            adherent: true,
            type: "structure",
            structureAddress: `${values.structureAddress} ${values.structureZipcode} ${values.structureCity}`,
          };

          try {
            let geoResponse = await fetch(
              "https://1zotern3p7.execute-api.eu-central-1.amazonaws.com/v1/forward?access_key=1d5afc3c0ddf175f11caddc72501cb5c&query=" +
                mapValues.structureAddress
            );
            /* if (!geoResponse.ok) {
            const message = `An error has occured: ${response.status}`;
            throw new Error(message);
          } */
            geoResponse = await geoResponse.json();
            mapValues = {
              ...mapValues,
              structureLat:
                JSON.stringify(geoResponse.data[0]?.latitude) || "",
              structureLong:
                JSON.stringify(geoResponse.data[0]?.longitude) || "",
            };
            let mapResponse = await usePost(
              "carte-adherents-2-s",
              mapValues
            );
            console.log(`mapResponse`, mapResponse);
          } catch (err) {
            const message = `An error has occured: ${err}`;
          }
        }

        // POST /subscribe-insti
        let mailResponse = await usePost("subscribe-insti", {
          email: values.referentEmail,
        });
        console.log(`mailResponse`, mailResponse);

        // redirect to Stripe
        if (values.reglement === "cb") {
          if (values.adhesionStructureType === "A") {
            if (values.adhesionTarifA === "tarifA1") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/cN27uH4691D82CkbIQ";
              }, 4000);
            } else if (values.adhesionTarifA === "tarifA2") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/00g5mz1Y11D84KscMV";
              }, 4000);
            } else if (values.adhesionTarifA === "tarifA3") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/bIYdT56eh4PkccU28i";
              }, 4000);
            }
            /* setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/fZe5mzauxchMccUfZ0";
              }, 4000); */
          } else if (values.adhesionStructureType === "B") {
            if (values.adhesionTarifB === "tarifB1") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/cN216jfOR0z4a4MfZ9";
              }, 4000);
            } else if (values.adhesionTarifB === "tarifB2") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/aEUcP1469dlQ3GoaEQ";
              }, 4000);
            } else if (values.adhesionTarifB === "tarifB3") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/eVadT5cCFa9E4Ks4gt";
              }, 4000);
            }
            /* setTimeout(() => {
              window.location.href =
                "https://buy.stripe.com/3cs5mz1Y1epUfp6fZ1";
            }, 4000); */
          } else if (values.adhesionStructureType === "C") {
            if (values.adhesionTarifC === "tarifC1") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/4gwaGT4694Pk3Go4gu";
              }, 4000);
            } else if (values.adhesionTarifC === "tarifC2") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/5kA6qD8mp0z4b8QbIX";
              }, 4000);
            } else if (values.adhesionTarifC === "tarifC3") {
              setTimeout(() => {
                window.location.href =
                  "https://buy.stripe.com/cN2dT5auxepU7WE14k";
              }, 4000);
            }
            /* setTimeout(() => {
              window.location.href =
                "https://buy.stripe.com/6oEeX9fOR5Tofp69AE";
            }, 4000); */
          } else {
            popAlert(
              `Erreur : veuillez réessayer ou nous contacter à <a href="mailto:contact@anrat.net" style="color:white;">contact@anrat.net</a>`,
              false
            );
          }
        } else if (values.reglement === "cheque") {
          if (values.adhesionStructureType === "A") {
            window.location.href = "/cheque/" + values.adhesionTarifA;
          } else if (values.adhesionStructureType === "B") {
            window.location.href = "/cheque/" + values.adhesionTarifB;
          } else if (values.adhesionStructureType === "C") {
            window.location.href = "/cheque/" + values.adhesionTarifC;
          }
        } else if (values.reglement === "rib") {
          if (values.adhesionStructureType === "A") {
            window.location.href = "/rib/" + values.adhesionTarifA;
          } else if (values.adhesionStructureType === "B") {
            window.location.href = "/rib/" + values.adhesionTarifB;
          } else if (values.adhesionStructureType === "C") {
            window.location.href = "/rib/" + values.adhesionTarifC;
          }
        }
      } else {
        popAlert(
          `Erreur : veuillez réessayer ou nous contacter à <a href="mailto:contact@anrat.net" style="color:white;">contact@anrat.net</a>`,
          false
        );
      }
    } else {
      popAlert(
        `Erreur : veuillez réessayer ou nous contacter à <a href="mailto:contact@anrat.net" style="color:white;">contact@anrat.net</a>`,
        false
      );
    }
  }

  function validateForm() {
    let valid = true;
    adhesionTypeError.innerHTML = "";
    if ($adhesionIndividuelle.checked) {
      let individualValues = {
        lastName: document
          .getElementById("lastName")
          .value.toUpperCase(),
        firstName: capitalizeFirstName(
          document.getElementById("firstName").value
        ),
        email: document.getElementById("email").value,
        phone: document.getElementById("phone").value,
        address: document.getElementById("address").value,
        zipcode: document.getElementById("zipcode").value,
        city: document.getElementById("city").value,
        country: document.getElementById("country").value,
        domaine: document.getElementById("domaine").value,
        culturalProfession: document.getElementById(
          "culturalProfession"
        ).value,
        individualStructureName: document.getElementById(
          "individualStructureName"
        ).value,
        individualStructureAddress: document.getElementById(
          "individualStructureAddress"
        ).value,
        individualStructureZipcode: document.getElementById(
          "individualStructureZipcode"
        ).value,
        individualStructureCity: document.getElementById(
          "individualStructureCity"
        ).value,
        individualStructureCountry: document.getElementById(
          "individualStructureCountry"
        ).value,
        function: document.getElementById("function").value,
        authCarteIndividual: document.getElementById(
          "auth-carte-individuel"
        ).checked,
        reglement: document.getElementById("reglement").value,
        civility: document.getElementById("civility").value,
        adhesionTarif: document.querySelector(
          "input[name=tarifType]:checked"
        )?.value,
      };

      let individualErrors = {
        lastName: document.getElementById("lastNameError"),
        firstName: document.getElementById("firstNameError"),
        email: document.getElementById("emailError"),
        phone: document.getElementById("phoneError"),
        address: document.getElementById("addressError"),
        zipcode: document.getElementById("zipcodeError"),
        city: document.getElementById("cityError"),
        country: document.getElementById("countryError"),
        domaine: document.getElementById("domaineError"),
        individualStructureName: document.getElementById(
          "individualStructureNameError"
        ),
        individualStructureAddress: document.getElementById(
          "individualStructureAddressError"
        ),
        individualStructureZipcode: document.getElementById(
          "individualStructureZipcodeError"
        ),
        individualStructureCity: document.getElementById(
          "individualStructureCityError"
        ),
        individualStructureCountry: document.getElementById(
          "individualStructureCountryError"
        ),
      };

      /* init errors */
      for (const [key, value] of Object.entries(individualErrors)) {
        value.innerHTML = "";
      }

      if (!individualValues.lastName) {
        valid = false;
        individualErrors.lastName.innerHTML =
          "Merci de renseigner votre nom";
      }

      if (!individualValues.firstName) {
        valid = false;
        individualErrors.firstName.innerHTML =
          "Merci de renseigner votre prénom";
      }

      if (
        !individualValues.email.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
      ) {
        valid = false;
        individualErrors.email.innerHTML =
          "Merci de renseigner une adresse courriel valide";
      }

      if (
        !individualValues.phone.match(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
        )
      ) {
        valid = false;
        individualErrors.phone.innerHTML =
          "Merci de renseigner un numéro de téléphone valide";
      }

      if (individualValues.address.length < 4) {
        valid = false;
        individualErrors.address.innerHTML =
          "Merci de renseigner une adresse valide";
      }

      if (individualValues.zipcode.length < 4) {
        valid = false;
        individualErrors.zipcode.innerHTML =
          "Merci de renseigner un code postal valide";
      }

      if (!individualValues.city) {
        valid = false;
        individualErrors.city.innerHTML =
          "Merci de renseigner une ville";
      }

      if (!individualValues.country) {
        valid = false;
        individualErrors.country.innerHTML =
          "Merci de renseigner un pays";
      }

      if (!individualValues.domaine) {
        valid = false;
        individualErrors.domaine.innerHTML =
          "Merci de renseigner votre domaine professionnel";
      }

      if (
        individualValues.individualStructureAddress &&
        individualValues.individualStructureAddress.length < 4
      ) {
        valid = false;
        individualErrors.individualStructureAddress.innerHTML =
          "Merci de renseigner une adresse valide pour votre structure";
      }

      if (
        individualValues.individualStructureZipcode &&
        individualValues.individualStructureZipcode.length < 4
      ) {
        valid = false;
        individualErrors.individualStructureZipcode.innerHTML =
          "Merci de renseigner un code postal valide pour votre structure";
      }

      if ($enseignant.value === "oui") {
        $enseignant.value = true;
        individualValues.profession = "enseignant";
        if ($grade.value === "superieur") {
          individualValues = {
            ...individualValues,
            university: document.getElementById("university").value,
            subject2: document.getElementById("subject2").value,
            fields: document.getElementById("fields").value,
          };

          individualErrors = {
            ...individualErrors,
            university: document.getElementById("universityError"),
            subject2: document.getElementById("subject2Error"),
            fields: document.getElementById("fieldsError"),
          };

          /* init errors */
          individualErrors.university.innerHTML = "";
          individualErrors.subject2.innerHTML = "";
          individualErrors.fields.innerHTML = "";

          if (!individualValues.university) {
            valid = false;
            individualErrors.university.innerHTML =
              "Merci de renseigner le nom de votre université";
          }

          if (!individualValues.subject2) {
            valid = false;
            individualErrors.subject2.innerHTML =
              "Merci de renseigner votre discipline";
          }
        }
        if ($grade.value === "premier_degre") {
          individualValues = {
            ...individualValues,
            academy1: document.getElementById("academy1").value,
            schoolType1: document.getElementById("schoolType1").value,
          };
        }

        if ($grade.value === "second_degre") {
          let subjects = [];
          document
            .querySelectorAll("input[name=subject]:checked")
            .forEach((subject) => {
              subjects.push(subject.value);
            });

          individualValues = {
            ...individualValues,
            academy2: document.getElementById("academy2").value,
            schoolType2: document.getElementById("schoolType2").value,
            subject: subjects.join(", "),
          };
        }
        individualValues = {
          ...individualValues,
          profession: "enseignant",
        };
      } else {
        individualValues = {
          ...individualValues,
          profession: document.getElementById("profession").value,
        };

        individualErrors = {
          ...individualErrors,
          profession: document.getElementById("professionError"),
        };

        individualErrors.profession.innerHTML = "";

        if (!individualValues.profession) {
          valid = false;
          individualErrors.profession.innerHTML =
            "Merci de renseigner votre profession";
        }
      }

      /* returns */
      if (valid) {
        individualValues.adhesionType = "individuelle";
        sendForm(individualValues);
        return true;
      } else {
        popAlert(
          `Merci de remplir tous les champs obligatoires.`,
          false
        );
        window.location.href = "#top";
        return false;
      }
    } else if ($adhesionStructure.checked) {
      let structureValues = {
        adhesionStructureType: document.querySelector(
          "input[name=adhesionStructureType]:checked"
        )?.value,
        structureName: document.getElementById("structureName").value,
        structureAddress: document.getElementById("structureAddress")
          .value,
        structureZipcode: document.getElementById("structureZipcode")
          .value,
        structureCity: document.getElementById("structureCity").value,
        structureCountry: document.getElementById("structureCountry")
          .value,
        structureWebsite: document.getElementById("structureWebsite")
          .value,
        referentLastName: document
          .getElementById("referentLastName")
          .value.toUpperCase(),
        referentFirstName: capitalizeFirstName(
          document.getElementById("referentFirstName").value
        ),
        referentEmail: document.getElementById("referentEmail").value,
        referentPhone: document.getElementById("referentPhone").value,
        referentProfession: document.getElementById(
          "referentProfession"
        ).value,
        authCarteStructure: document.getElementById(
          "auth-carte-structure"
        ).checked,
        reglement: document.getElementById("reglement-structure")
          .value,
        referentCivility: document.getElementById("referentCivility")
          .value,
        adhesionTarifA: document.querySelector(
          "input[name=tarifTypeA]:checked"
        )?.value,
        adhesionTarifB: document.querySelector(
          "input[name=tarifTypeB]:checked"
        )?.value,
        adhesionTarifC: document.querySelector(
          "input[name=tarifTypeC]:checked"
        )?.value,
      };

      let structureErrors = {
        adhesionStructureType: document.getElementById(
          "adhesionStructureTypeError"
        ),
        structureName: document.getElementById("structureNameError"),
        structureAddress: document.getElementById(
          "structureAddressError"
        ),
        structureZipcode: document.getElementById(
          "structureZipcodeError"
        ),
        structureCity: document.getElementById("structureCityError"),
        structureCountry: document.getElementById(
          "structureCountryError"
        ),
        structureWebsite: document.getElementById(
          "structureWebsiteError"
        ),
        referentLastName: document.getElementById(
          "referentLastNameError"
        ),
        referentFirstName: document.getElementById(
          "referentFirstNameError"
        ),
        referentEmail: document.getElementById("referentEmailError"),
        referentPhone: document.getElementById("referentPhoneError"),
        referentProfession: document.getElementById(
          "referentProfessionError"
        ),
      };

      /* init errors */
      for (const [key, value] of Object.entries(structureErrors)) {
        value.innerHTML = "";
      }

      if (!structureValues.adhesionStructureType) {
        valid = false;
        structureErrors.adhesionStructureType.innerHTML =
          "Merci de renseigner le tarif correspondant à votre type de structure";
      }

      if (!structureValues.structureName) {
        valid = false;
        structureErrors.structureName.innerHTML =
          "Merci de renseigner le nom de votre structure";
      }

      if (structureValues.structureAddress.length < 4) {
        valid = false;
        structureErrors.structureAddress.innerHTML =
          "Merci de renseigner une adresse valide";
      }

      if (structureValues.structureZipcode.length < 4) {
        valid = false;
        structureErrors.structureZipcode.innerHTML =
          "Merci de renseigner un code postal valide";
      }

      if (!structureValues.structureCity) {
        valid = false;
        structureErrors.structureCity.innerHTML =
          "Merci de renseigner la ville de votre structure";
      }

      if (!structureValues.structureCountry) {
        valid = false;
        structureErrors.structureCountry.innerHTML =
          "Merci de renseigner le pays de votre structure";
      }

      if (
        structureValues.structureWebsite &&
        !structureValues.structureWebsite.match(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        )
      ) {
        valid = false;
        structureErrors.structureWebsite.innerHTML =
          "Merci de renseigner une adresse web valide";
      }

      if (!structureValues.referentLastName) {
        valid = false;
        structureErrors.referentLastName.innerHTML =
          "Merci de renseigner le nom du référent";
      }

      if (!structureValues.referentFirstName) {
        valid = false;
        structureErrors.referentFirstName.innerHTML =
          "Merci de renseigner le prénom du référent";
      }

      if (
        !structureValues.referentEmail.match(
          /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        )
      ) {
        valid = false;
        structureErrors.referentEmail.innerHTML =
          "Merci de renseigner une adresse courriel valide";
      }

      if (
        !structureValues.referentPhone.match(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
        )
      ) {
        valid = false;
        structureErrors.referentPhone.innerHTML =
          "Merci de renseigner un numéro de téléphone valide";
      }

      if (!structureValues.referentProfession) {
        valid = false;
        structureErrors.referentProfession.innerHTML =
          "Merci de renseigner la profession du référent";
      }

      /* return values */
      if (valid) {
        structureValues.adhesionType = "structure";
        sendForm(structureValues);
        return true;
      } else {
        popAlert(
          `Merci de remplir tous les champs obligatoires.`,
          false
        );
        window.location.href = "#top";
        return false;
      }
    } else {
      adhesionTypeError.innerHTML =
        "Merci de renseigner un type d'adhésion";
      return false;
    }
  }

  $adhesionIndividuelle.addEventListener("click", (ev) => {
    adhesionTypeError.innerHTML = "";
    if ($adhesionIndividuelle.checked) {
      $structureForm.style.display = "none";
      $individualForm.style.display = "block";
    }
  });

  $adhesionStructure.addEventListener("click", (ev) => {
    adhesionTypeError.innerHTML = "";
    if ($adhesionStructure.checked) {
      $individualForm.style.display = "none";
      $structureForm.style.display = "block";
    }
  });

  $typeA.addEventListener("click", (ev) => {
    $tarifA.style.display = "grid";
    $tarifB.style.display = "none";
    $tarifC.style.display = "none";
  });

  $typeB.addEventListener("click", (ev) => {
    $tarifB.style.display = "grid";
    $tarifA.style.display = "none";
    $tarifC.style.display = "none";
  });

  $typeC.addEventListener("click", (ev) => {
    $tarifC.style.display = "grid";
    $tarifA.style.display = "none";
    $tarifB.style.display = "none";
  });

  $domaine.addEventListener("change", (ev) => {
    if ($domaine.value === "culture") {
      $culture.style.display = "flex";
      $education.style.display = "none";
      $enseignantForm.style.display = "none";
      $nonEnseignantForm.style.display = "block";
    } else if ($domaine.value === "education") {
      $education.style.display = "block";
      $culture.style.display = "none";
    } else if ($domaine.value === "autre") {
      $education.style.display = "none";
      $culture.style.display = "none";
      $enseignantForm.style.display = "none";
      $nonEnseignantForm.style.display = "block";
    } else {
      $education.style.display = "none";
      $culture.style.display = "none";
      console.log("error");
    }
  });

  $enseignant.addEventListener("change", (ev) => {
    if ($enseignant.value === "oui") {
      $nonEnseignantForm.style.display = "none";
      $enseignantForm.style.display = "block";
    } else {
      $enseignantForm.style.display = "none";
      $nonEnseignantForm.style.display = "block";
    }
  });

  $grade.addEventListener("change", (ev) => {
    if ($grade.value === "premier_degre") {
      $secondGrade.style.display = "none";
      $superior.style.display = "none";
      $firstGrade.style.display = "block";
    }

    if ($grade.value === "second_degre") {
      $firstGrade.style.display = "none";
      $superior.style.display = "none";
      $secondGrade.style.display = "block";
    }

    if ($grade.value === "superieur") {
      $firstGrade.style.display = "none";
      $secondGrade.style.display = "none";
      $superior.style.display = "block";
    }
  });

  $adhesionForm.addEventListener("submit", (ev) => {
    ev.preventDefault();
    validateForm() ? console.log("okeey") : console.log("oh nooo");
  });
}

async function renderAdhesion() {
  const $main = document.getElementById("main");
  $main.classList.remove("grid");
  let blocsHTML = await renderBlocs();

  /* Formulaire */
  $main.innerHTML = `<section>
        <div class="ariane">
         <a href="/index.html">Accueil</a> / <a href="/anrat/">ANRAT</a> / Adhérer à l'ANRAT
        </div>
        <h1 id="top">Adhérer à l'ANRAT</h1>
        <p>Les adhésions sont valables sur l'année civile.</p>
        <p>Toutes les adhésions enregistrées à partir du 1er décembre seront systématiquement reportées sur l'année suivante.</p>
        <div id="adherer" class="adherer">
          <form class="form" id="adhesionForm">
        <div class="form__field">
          <label for="adhesionIndividuelle">Adhésion individuelle</label>
          <input
            type="radio"
            name="adhesionType"
            id="adhesionIndividuelle"
            value="individuelle"
          />
          <label for="adhesionStructure">Adhésion structure</label>
          <input
            type="radio"
            name="adhesionType"
            id="adhesionStructure"
            value="structure"
          />
          <span id="adhesionTypeError" class="form__field__error"></span>
        </div>

        <!-- individuelle -->
        <div class="individual">
          <p class="obligatoire">Champs obligatoires (<span class="purple">*</span>)</p>
          <div class="form__field text-input">
            <label for="civility">Civilité <span class="purple">*</span></label>
            <select name="civility" id="civility">
              <option value="Mme">Madame</option>
              <option value="M">Monsieur</option>
            </select>
          </div>
          <div class="form__field text-input">
            <label for="lastName">Nom <span class="purple">*</span></label>
            <input type="text" name="lastName" id="lastName" />
            <span id="lastNameError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="firstName">Prénom <span class="purple">*</span></label>
            <input type="text" name="firstName" id="firstName" />
            <span id="firstNameError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="email">Email <span class="purple">*</span></label>
            <input type="email" name="email" id="email" />
            <span id="emailError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="phone">Téléphone <span class="purple">*</span></label>
            <input type="tel" name="phone" id="phone" />
            <span id="phoneError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="address">Adresse <span class="purple">*</span></label>
            <input type="text" name="address" id="address" />
            <span id="addressError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="zipcode"
              >Code Postal <span class="purple">*</span></label
            >
            <input type="text" name="zipcode" id="zipcode" />
            <span id="zipcodeError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="city">Ville <span class="purple">*</span></label>
            <input type="text" name="city" id="city" />
            <span id="cityError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="country">Pays <span class="purple">*</span></label>
            <input type="text" name="country" id="country" />
            <span id="countryError" class="form__field__error"></span>
          </div>
          <p></p>
          <div class="form__field text-input">
            <label for="domaine">Je suis professionnel.le <span class="purple">*</span></label>
            <select name="domaine" id="domaine">
              <option value="">---</option>
              <option value="culture">de la culture</option>
              <option value="education">de l'éducation</option>
              <option value="autre">autre domaine</option>
            </select>
            <span id="domaineError" class="form__field__error"></span>
          </div>

          <!-- culture -->
          <div class="form__field culture text-input" id="culture">
              <label for="culturalProfession"
                >Vous êtes professionnel.le de la culture
              </label>
              <select name="culturalProfession" id="culturalProfession">
                <option value="">---</option>
                <option value="Ministere">
                  en poste au ministère
                </option>
                <option value="DRAC">en poste en DRAC</option>
                <option value="Artiste">Artiste</option>
                <option value="Autre">Autre</option>
              </select>
          </div>

          <!-- education -->
          <div class="education" id="education">
            <div class="form__field">
              <label for="enseignantBool">Êtes vous enseigant.e ?</label>
              <select name="enseignantBool" id="enseignantBool">
              <option value="non">non</option>
                <option value="oui">oui</option>
              </select>
            </div>
          

          <!-- enseignant -->
          <div class="enseignant">
            <div class="form__field text-input">
              <label for="grade"
                >Vous êtes enseignant.e <span class="purple">*</span></label
              >
              <select name="grade" id="grade">
                <option value="premier_degre">du 1er degré</option>
                <option value="second_degre">du 2nd degré</option>
                <option value="superieur">du supérieur</option>
              </select>
            </div>

            <!-- 1er degré -->
            <div class="firstGrade">
              <div class="form__field text-input">
                <label for="academy1"
                  >Votre académie <span class="purple">*</span></label
                >
                <select name="academy1" id="academy1">
                  <option value="Académie de Besançon">
                    Académie de Besançon
                  </option>
                  <option value="Académie de Bordeaux">
                    Académie de Bordeaux
                  </option>
                  <option value="Académie de Clermont-Ferrand">
                    Académie de Clermont-Ferrand
                  </option>
                  <option value="Académie de Dijon">Académie de Dijon</option>
                  <option value="Académie de Grenoble">
                    Académie de Grenoble
                  </option>
                  <option value="Académie de la Guadeloupe">
                    Académie de la Guadeloupe
                  </option>
                  <option value="Académie de la Guyane">
                    Académie de la Guyane
                  </option>
                  <option value="Académie de la Martinique">
                    Académie de la Martinique
                  </option>
                  <option value="Académie de La Réunion">
                    Académie de La Réunion
                  </option>
                  <option value="Académie de Limoges">
                    Académie de Limoges
                  </option>
                  <option value="Académie de Lyon">Académie de Lyon</option>
                  <option value="Académie de Poitiers">
                    Académie de Poitiers
                  </option>
                  <option value="Académie de Mayotte">
                    Académie de Mayotte
                  </option>
                  <option value="Académie d'Aix-Marseille">
                    Académie d'Aix-Marseille
                  </option>
                  <option value="Académie d'Amiens">Académie d'Amiens</option>
                  <option value="Académie d'Orléans-Tours">
                    Académie d'Orléans-Tours
                  </option>
                  <option value="Académie de Corse">Académie de Corse</option>
                  <option value="Académie de Lille">Académie de Lille</option>
                  <option value="Académie de Nancy-Metz">
                    Académie de Nancy-Metz
                  </option>
                  <option value="Académie de Nantes">Académie de Nantes</option>
                  <option value="Académie de Nice">Académie de Nice</option>
                  <option value="Académie de Normandie">
                    Académie de Normandie
                  </option>
                  <option value="Académie de Reims">Académie de Reims</option>
                  <option value="Académie de Rennes">Académie de Rennes</option>
                  <option value="Académie de Strasbourg">
                    Académie de Strasbourg
                  </option>
                  <option value="Académie de Créteil">
                    Académie de Créteil
                  </option>
                  <option value="Académie de Montpellier">
                    Académie de Montpellier
                  </option>
                  <option value="Académie de Paris">Académie de Paris</option>
                  <option value="Académie de Toulouse">
                    Académie de Toulouse
                  </option>
                  <option value="Académie de Versailles">
                    Académie de Versailles
                  </option>
                </select>
              </div>
              <div class="form__field text-input">
                <label for="schoolType1"
                  >Vous enseignez en école <span class="purple">*</span></label
                >
                <select name="schoolType1" id="schoolType1">
                  <option value="primaire">primaire</option>
                  <option value="maternelle">maternelle</option>
                </select>
              </div>
            </div>

            <!-- 2nd degré -->
            <div class="secondGrade">
              <div class="form__field text-input">
                <label for="academy2"
                  >Votre académie <span class="purple">*</span></label
                >
                <select name="academy2" id="academy2">
                  <option value="Académie de Besançon">
                    Académie de Besançon
                  </option>
                  <option value="Académie de Bordeaux">
                    Académie de Bordeaux
                  </option>
                  <option value="Académie de Clermont-Ferrand">
                    Académie de Clermont-Ferrand
                  </option>
                  <option value="Académie de Dijon">Académie de Dijon</option>
                  <option value="Académie de Grenoble">
                    Académie de Grenoble
                  </option>
                  <option value="Académie de la Guadeloupe">
                    Académie de la Guadeloupe
                  </option>
                  <option value="Académie de la Guyane">
                    Académie de la Guyane
                  </option>
                  <option value="Académie de la Martinique">
                    Académie de la Martinique
                  </option>
                  <option value="Académie de La Réunion">
                    Académie de La Réunion
                  </option>
                  <option value="Académie de Limoges">
                    Académie de Limoges
                  </option>
                  <option value="Académie de Lyon">Académie de Lyon</option>
                  <option value="Académie de Poitiers">
                    Académie de Poitiers
                  </option>
                  <option value="Académie de Mayotte">
                    Académie de Mayotte
                  </option>
                  <option value="Académie d'Aix-Marseille">
                    Académie d'Aix-Marseille
                  </option>
                  <option value="Académie d'Amiens">Académie d'Amiens</option>
                  <option value="Académie d'Orléans-Tours">
                    Académie d'Orléans-Tours
                  </option>
                  <option value="Académie de Corse">Académie de Corse</option>
                  <option value="Académie de Lille">Académie de Lille</option>
                  <option value="Académie de Nancy-Metz">
                    Académie de Nancy-Metz
                  </option>
                  <option value="Académie de Nantes">Académie de Nantes</option>
                  <option value="Académie de Nice">Académie de Nice</option>
                  <option value="Académie de Normandie">
                    Académie de Normandie
                  </option>
                  <option value="Académie de Reims">Académie de Reims</option>
                  <option value="Académie de Rennes">Académie de Rennes</option>
                  <option value="Académie de Strasbourg">
                    Académie de Strasbourg
                  </option>
                  <option value="Académie de Créteil">
                    Académie de Créteil
                  </option>
                  <option value="Académie de Montpellier">
                    Académie de Montpellier
                  </option>
                  <option value="Académie de Paris">Académie de Paris</option>
                  <option value="Académie de Toulouse">
                    Académie de Toulouse
                  </option>
                  <option value="Académie de Versailles">
                    Académie de Versailles
                  </option>
                </select>
              </div>
              <div class="form__field text-input">
                <label for="schoolType2"
                  >Vous enseignez en <span class="purple">*</span></label
                >
                <select name="schoolType2" id="schoolType2">
                  <option value="collège">Collège</option>
                  <option value="Lycée général et technologique">
                    Lycée général et technologique
                  </option>
                  <option value="Lycée professionnel">
                    Lycée professionnel 2
                  </option>
                </select>
              </div>
              <div class="form__field subject">
                <legend>Quelle(s) discipline(s) enseignez-vous ? :</legend>
                <div class="subject-grid">
                <input
                  type="checkbox"
                  id="mathematiques"
                  name="subject"
                  value="Mathématiques"
                />
                <label for="mathematiques">Mathématiques</label>
                <input
                  type="checkbox"
                  id="francais"
                  name="subject"
                  value="Français"
                />
                <label for="francais">Français</label>
                <input
                  type="checkbox"
                  id="theatre"
                  name="subject"
                  value="Théâtre"
                />
                <label for="theatre">Théâtre</label>
                <input
                  type="checkbox"
                  id="histoire"
                  name="subject"
                  value="Histoire-géographie"
                />
                <label for="histoire">Histoire-géographie</label>
                <input
                  type="checkbox"
                  id="ses"
                  name="subject"
                  value="Sciences économiques et sociales"
                />
                <label for="ses">Sciences économiques et sociales</label>
                <input type="checkbox" id="eps" name="subject" value="EPS" />
                <label for="eps">EPS</label>
                <input type="checkbox" id="svt" name="subject" value="SVT" />
                <label for="svt">SVT</label>
                <input
                  type="checkbox"
                  id="physique"
                  name="subject"
                  value="Physique-chimie"
                />
                <label for="physique">Physique-chimie</label>
                <input
                  type="checkbox"
                  id="langue"
                  name="subject"
                  value="Langue vivante"
                />
                <label for="langue">Langue vivante</label>
                <input
                  type="checkbox"
                  id="documentaliste"
                  name="subject"
                  value="Documentaliste"
                />
                <label for="documentaliste">Documentaliste</label>
                <input
                  type="checkbox"
                  id="autres"
                  name="subject"
                  value="Autres"
                />
                <label for="autres">Autres</label>
                </div>
              </div>
            </div>

            <!-- supérieur -->
            <div class="superior">
              <div class="form__field text-input">
                <label for="university"
                  >Nom de votre université et éventuellement de votre
                  laboratoire <span class="purple">*</span></label
                >
                <input type="text" name="university" id="university" />
                <span id="universityError" class="form__field__error"></span>
              </div>
              <div class="form__field text-input">
                <label for="subject2"
                  >La discipline que vous enseignez
                  <span class="purple">*</span>
                </label>
                <input type="text" name="subject2" id="subject2" />
                <span id="subject2Error" class="form__field__error"></span>
              </div>
              <div class="form__field text-input">
                <label for="fields">Thématiques de recherche</label>
                <input type="text" name="fields" id="fields" />
                <span id="fieldsError" class="form__field__error"></span>
              </div>
            </div>
          </div>
        </div>

          <!-- pas enseignant -->
          <div class="non-enseignant">
            <div class="form__field text-input">
              <label for="profession"
                >Profession <span class="purple">*</span></label
              >
              <input type="text" name="profession" id="profession" />
              <span id="professionError" class="form__field__error"></span>
            </div>
          </div>

          <!-- structure (individuelle) -->
          <div class="individualStructure">
            <div class="form__field text-input">
              <label for="individualStructureName"
                >Nom de votre structure
              </label>
              <input
                type="text"
                name="individualStructureName"
                id="individualStructureName"
              />
              <span
                id="individualStructureNameError"
                class="form__field__error"
              ></span>
            </div>
            <div class="form__field text-input">
              <label for="individualStructureAddress"
                >Adresse de votre structure
              </label>
              <input
                type="text"
                name="individualStructureAddress"
                id="individualStructureAddress"
              />
              <span
                id="individualStructureAddressError"
                class="form__field__error"
              ></span>
            </div>
            <div class="form__field text-input">
              <label for="individualStructureZipcode"
                >Code postal de votre structure
              </label>
              <input
                type="text"
                name="individualStructureZipcode"
                id="individualStructureZipcode"
              />
              <span
                id="individualStructureZipcodeError"
                class="form__field__error"
              ></span>
            </div>
            <div class="form__field text-input">
              <label for="individualStructureCity"
                >Ville de votre structure
              </label>
              <input
                type="text"
                name="individualStructureCity"
                id="individualStructureCity"
              />
              <span
                id="individualStructureCityError"
                class="form__field__error"
              ></span>
            </div>
            <div class="form__field text-input">
              <label for="individualStructureCountry"
                >Pays de votre structure
              </label>
              <input
                type="text"
                name="individualStructureCountry"
                id="individualStructureCountry"
              />
              <span
                id="individualStructureCountryError"
                class="form__field__error"
              ></span>
            </div>
            <div class="form__field text-input">
              <label for="function"
                >Votre fonction au sein de la structure
              </label>
              <select name="function" id="function">
                <option value="">---</option>
                <option value="Relations">
                  Relations publiques et ou action culturelle
                </option>
                <option value="Communication">Communication</option>
                <option value="Administration">Administration</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
            <div class="form__field auth">
              <label for="auth-carte-individuel">J’autorise l’ANRAT à faire figurer mon nom, mon prénom et ma profession sur la carte du réseau en ligne</label>
                <input
                  type="checkbox"
                  id="auth-carte-individuel"
                  name="auth-carte-individuel"
                />
            </div>
            <p><span style="color: #8D1F7E; font-weight: bold;">NOUVEAU</span> À partir de 2023 de nouveaux tarifs d'adhésion entrent en vigueur. Plusieurs formules sont proposées. Au-delà des droits ouverts à tout adhérent, vous pouvez choisir de manifester votre soutien en choisissant le <span class="bold">tarif ami</span> ou le <span class="bold">tarif militant</span>.</p>
            <p>un DVD du film L'école en actes sera offert à tous les adhérents "militants".</p>
            <div class="form__field tarifs">
              <label for="tarifI1" class="tarif-individuel">Tarif adhérent : 30€</label>
              <input
              type="radio"
              name="tarifType"
              id="tarifI1"
              value="tarifI1"
              checked
            />
            <label for="tarifI2" class="tarif-individuel">Tarif ami : 50€</label>
              <input
              type="radio"
              name="tarifType"
              id="tarifI2"
              value="tarifI2"
            />
            <label for="tarifI3" class="tarif-individuel">Tarif militant : 70€</label>
              <input
              type="radio"
              name="tarifType"
              id="tarifI3"
              value="tarifI3"
            />
            </div>
          <div class="form__field text-input">
            <label for="reglement">Je souhaite régler par</label>
            <select name="reglement" id="reglement">
              <option value="cb">CB (recommandé)</option>
              <option value="cheque">Chèque</option>
              <option value="rib">Virement bancaire</option>
            </select>
          </div>
          </div>
        </div>

        <!-- structure -->
        <div class="structure">
          <p class="obligatoire">Champs obligatoires (<span class="purple">*</span>)</p>
          <div class="form__field tarif">
          <label for="typeA"
              >Tarif A : pour les structures de type petites associations
              et compagnies, pour les établissements scolaires du premier degré
              et les collèges.</label
            >
            <input
              type="radio"
              name="adhesionStructureType"
              id="typeA"
              value="A"
            />
            </div>
            <div class="form__field tarif">
            <label for="typeB"
              >Tarif B : pour les structures privées, les Scènes
              conventionnées, les festivals, les Théâtres de ville, les
              collectivités territoriales, les lycées et les Universités.</label
            >
            <input
              type="radio"
              name="adhesionStructureType"
              id="typeB"
              value="B"
            />
            </div>
            <div class="form__field tarif">
            <label for="typeC"
              >Tarif C : pour les structures de type Théâtres nationaux,
              CDN, Scènes nationales et associations nationales.</label
            >
            <input
              type="radio"
              name="adhesionStructureType"
              id="typeC"
              value="C"
            />
            <span class="purple">*</span>
            <span
              id="adhesionStructureTypeError"
              class="form__field__error"
            ></span>
          </div>
          <div class="form__field text-input">
            <label for="structureName"
              >Nom de la structure <span class="purple">*</span></label
            >
            <input type="text" name="structureName" id="structureName" />
            <span id="structureNameError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="structureAddress"
              >Adresse de la structure <span class="purple">*</span></label
            >
            <input type="text" name="structureAddress" id="structureAddress" />
            <span id="structureAddressError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="structureZipcode"
              >Code postal de la structure <span class="purple">*</span></label
            >
            <input type="text" name="structureZipcode" id="structureZipcode" />
            <span id="structureZipcodeError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="structureCity"
              >Ville de la structure <span class="purple">*</span></label
            >
            <input type="text" name="structureCity" id="structureCity" />
            <span id="structureCityError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="structureCountry"
              >Pays de la structure <span class="purple">*</span></label
            >
            <input type="text" name="structureCountry" id="structureCountry" />
            <span id="structureCountryError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="structureWebsite">Site web de la structure</label>
            <input type="text" name="structureWebsite" id="structureWebsite" />
            <span id="structureWebsiteError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="referentCivility">Civilité du référent <span class="purple">*</span></label>
            <select name="referentCivility" id="referentCivility">
              <option value="Mme">Madame</option>
              <option value="M">Monsieur</option>
            </select>
          </div>
          <div class="form__field text-input">
            <label for="referentLastName"
              >Nom du référent <span class="purple">*</span></label
            >
            <input type="text" name="referentLastName" id="referentLastName" />
            <span id="referentLastNameError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="referentFirstName"
              >Prénom du référent <span class="purple">*</span></label
            >
            <input
              type="text"
              name="referentFirstName"
              id="referentFirstName"
            />
            <span id="referentFirstNameError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="referentEmail"
              >Email du référent <span class="purple">*</span></label
            >
            <input type="email" name="referentEmail" id="referentEmail" />
            <span id="referentEmailError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="referentPhone"
              >Téléphone du référent <span class="purple">*</span></label
            >
            <input type="text" name="referentPhone" id="referentPhone" />
            <span id="referentPhoneError" class="form__field__error"></span>
          </div>
          <div class="form__field text-input">
            <label for="referentProfession"
              >Profession du référent <span class="purple">*</span></label
            >
            <input
              type="text"
              name="referentProfession"
              id="referentProfession"
            />
            <span
              id="referentProfessionError"
              class="form__field__error"
            ></span>
          </div>
          <div class="form__field auth">
              <label for="auth-carte-structure">J’autorise l’ANRAT à faire figurer le nom, l’adresse et le site web de la structure sur la carte du réseau en ligne</label>
                <input
                  type="checkbox"
                  id="auth-carte-structure"
                  name="auth-carte-structure"
                />
            </div>
             <p><span style="color: #8D1F7E; font-weight: bold;">NOUVEAU</span> À partir de 2023 de nouveaux tarifs d'adhésion entrent en vigueur. Plusieurs formules sont proposées. Au-delà des droits ouverts à tout adhérent, vous pouvez choisir de manifester votre soutien en choisissant le <span class="bold">tarif ami</span> ou le <span class="bold">tarif militant</span>.</p>
            <div class="form__field" id="tarifA" class="tarifs">
              <label for="tarifA1" class="tarif-individuel">Tarif Adhérent : 70€</label>
              <input
              type="radio"
              name="tarifTypeA"
              id="tarifA1"
              value="tarifA1"
              checked
            />
            <label for="tarifA2" class="tarif-individuel">Tarif Ami : 100€</label>
              <input
              type="radio"
              name="tarifTypeA"
              id="tarifA2"
              value="tarifA2"
            />
            <label for="tarifA3" class="tarif-individuel">Tarif Militant : 150€</label>
              <input
              type="radio"
              name="tarifTypeA"
              id="tarifA3"
              value="tarifA3"
            />
            </div>
            <div class="form__field" id="tarifB">
              <label for="tarifB1" class="tarif-individuel">Tarif Adhérent : 150€</label>
              <input
              type="radio"
              name="tarifTypeB"
              id="tarifB1"
              value="tarifB1"
              checked
            />
            <label for="tarifB2" class="tarif-individuel">Tarif Ami : 180€</label>
              <input
              type="radio"
              name="tarifTypeB"
              id="tarifB2"
              value="tarifB2"
            />
            <label for="tarifB3" class="tarif-individuel">Tarif Militant : 230€</label>
              <input
              type="radio"
              name="tarifTypeB"
              id="tarifB3"
              value="tarifB3"
            />
            </div>
            <div class="form__field" id="tarifC">
              <label for="tarifC1" class="tarif-individuel">Tarif Adhérent : 250€</label>
              <input
              type="radio"
              name="tarifTypeC"
              id="tarifC1"
              value="tarifC1"
              checked
            />
            <label for="tarifC2" class="tarif-individuel">Tarif Ami : 280€</label>
              <input
              type="radio"
              name="tarifTypeC"
              id="tarifC2"
              value="tarifC2"
            />
            <label for="tarifC3" class="tarif-individuel">Tarif Militant : 330€</label>
              <input
              type="radio"
              name="tarifTypeC"
              id="tarifC3"
              value="tarifC3"
            />
            </div>
            <div class="form__field text-input">
            <label for="reglement-structure">Je souhaite régler par</label>
            <select name="reglement-structure" id="reglement-structure">
              <option value="cb">CB (recommandé)</option>
              <option value="cheque">Chèque</option>
              <option value="rib">Virement bancaire</option>
            </select>
          </div>
        </div>

        <div class="submit">
          <button type="submit" id="submit">Adhérer à l'ANRAT</button>
        </div>
      </form>

      <div id="output"></div>
        </div>
      </section>
      <div class="blocs">
        ${blocsHTML}
      </div>`;

  /* $main.innerHTML = `<h1 style="color:white;">Le formualire d'adhésion est en maintenance pour des raisons techniques. Il sera rétabli dès demain. Merci de votre compréhension.</h1>`; */
  handleAdhesion();
}

export { renderAdhesion };
